import en from './locales/en.json'
import ru from './locales/ru.json'
import pt from './locales/pt.json'
import es from './locales/es.json'

export default defineI18nConfig(() => ({
  messages: {
    en,
    ru,
    pt,
    es,
  },
}))
